var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "aside aside-left aside-fixed d-flex flex-column flex-row-auto",
        class: _vm.burgerMenu ? "aside-on" : "",
        attrs: { id: "tc_aside" },
      },
      [
        _c(
          "div",
          { staticClass: "brand flex-column-auto", attrs: { id: "tc_brand" } },
          [
            _c(
              "router-link",
              { staticClass: "brand-logo", attrs: { to: "/admin/dashboard" } },
              [
                _c("img", {
                  staticClass: "brand-image",
                  staticStyle: { height: "25px" },
                  attrs: { alt: "Logo", src: "/assets/images/misc/r.png" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "brand-text" }, [
                  _c("img", {
                    staticStyle: { height: "25px" },
                    attrs: { alt: "Logo", src: "/assets/images/misc/logo.png" },
                  }),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "aside-menu-wrapper flex-column-fluid overflow-auto h-100",
            attrs: { id: "tc_aside_menu_wrapper" },
          },
          [
            _c(
              "div",
              {
                staticClass: "aside-menu mb-5",
                attrs: {
                  id: "tc_aside_menu",
                  "data-menu-vertical": "1",
                  "data-menu-scroll": "1",
                  "data-menu-dropdown-timeout": "500",
                },
              },
              [
                _c("div", { attrs: { id: "accordion" } }, [
                  _c("ul", { staticClass: "nav flex-column" }, [
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/admin/dashboard" },
                          },
                          [
                            _c("span", { staticClass: "svg-icon nav-icon" }, [
                              _c(
                                "svg",
                                {
                                  staticClass: "feather feather-home",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("polyline", {
                                    attrs: { points: "9 22 9 12 15 12 15 22" },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "nav-text" }, [
                              _vm._v(" Dashboard "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("media")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("media") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                                "data-target": "#media",
                                role: "button",
                                "aria-expanded": "false",
                                "aria-controls": "media",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("media")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "svg-icon nav-icon" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "feather feather-image",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "20px",
                                      height: "20px",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      stroke: "currentColor",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "3",
                                        y: "3",
                                        width: "18",
                                        height: "18",
                                        rx: "2",
                                        ry: "2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: { cx: "8.5", cy: "8.5", r: "1.5" },
                                    }),
                                    _vm._v(" "),
                                    _c("polyline", {
                                      attrs: { points: "21 15 16 10 5 21" },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Media"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("media"),
                              },
                              attrs: {
                                id: "media",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes(
                                  "media-settings"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/media-setting",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Media Settings")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("manage-media")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/media" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Manage Media")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("catalog")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("catalog") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("catalog")
                                },
                              },
                            },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Catalog"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("catalog"),
                              },
                              attrs: {
                                id: "catalog",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion1" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes(
                                    "product-unit-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-unit",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Product Units")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-attribute-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-attribute",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Product Attributes")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-variation-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-variation",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Product Variations")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-brand-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-brand",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Product Brands")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-category-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-category",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Product Categories")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-list"
                                  )
                                    ? _c("li", { staticClass: "nav-item" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              "data-toggle": "collapse",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setChildActive(
                                                  "product"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Products")]
                                            ),
                                            _vm._v(" "),
                                            _c("i", {
                                              staticClass:
                                                "fas fa-chevron-right fa-rotate-90",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          {
                                            staticClass: "nav flex-column",
                                            style:
                                              _vm.activeChildItem ==
                                                "product" && _vm.toggleChildMenu
                                                ? "display:block"
                                                : "display:none",
                                          },
                                          [
                                            _vm.$parent.permissions.includes(
                                              "product-list"
                                            )
                                              ? _c(
                                                  "li",
                                                  { staticClass: "nav-item" },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "nav-link mini-sub-nav-link",
                                                        attrs: {
                                                          to: "/admin/products",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "nav-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "List Products"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.$parent.permissions.includes(
                                              "product-manage"
                                            )
                                              ? _c(
                                                  "li",
                                                  { staticClass: "nav-item" },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "nav-link mini-sub-nav-link",
                                                        attrs: {
                                                          to: "/admin/add-product",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "nav-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Add Product"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.$parent.permissions.includes(
                                              "import-product"
                                            )
                                              ? _c(
                                                  "li",
                                                  { staticClass: "nav-item" },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "nav-link mini-sub-nav-link",
                                                        attrs: {
                                                          to: "/admin/verify-purchase-code",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "nav-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Import / Export"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "product-reviews"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/product-reviews",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Product Reviews")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("stock")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("stock") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("stock")
                                },
                              },
                            },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Stock / Inventory"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("stock"),
                              },
                              attrs: {
                                id: "stock",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion1" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes("stock-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/stocks" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("List Stock")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "stock-manage"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/add-stock" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Add Stock")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "stock-transfer-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/stock-transfers",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("List Stock Transfers")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "stock-transfer-manage"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/stock-transfer",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Add Stock Transfer")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("quotation-list")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("quotations") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#Quotations",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("quotations")
                                },
                              },
                            },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Quotations"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("quotations"),
                              },
                              attrs: {
                                id: "Quotations",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes(
                                  "quotation-list"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/quotations" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [
                                                _vm._v(
                                                  "List Purchase Quotations"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes(
                                  "quotation-manage"
                                )
                                  ? _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link sub-nav-link",
                                          attrs: {
                                            href: "/admin/add-quotation",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "bi bi-circle",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "10px",
                                                    height: "10px",
                                                    fill: "currentColor",
                                                    viewBox: "0 0 16 16",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "fill-rule": "evenodd",
                                                      d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "nav-text" },
                                            [_vm._v("Add Purchase Quotation")]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes(
                                  "quotation-sale-manage"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/sale-quotations",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("List Sale Quotations")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes(
                                  "quotation-manage"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/add-sale-quotation",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Add Sale Quotation")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("purchase-list")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("purchase") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#catalogPurchase",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("purchase")
                                },
                              },
                            },
                            [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Purchase"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("purchase"),
                              },
                              attrs: {
                                id: "catalogPurchase",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes(
                                  "purchase-list"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/purchases" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("List Purchases")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes(
                                  "purchase-manage"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/add-purchase",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Add Purchase")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("sale-order")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("sale") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#order",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("sale")
                                },
                              },
                            },
                            [
                              _vm._m(4),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Sell / Orders"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("sale"),
                              },
                              attrs: {
                                id: "order",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes("order-list")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/orders" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Orders")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("sale-manage")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/add-sale" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Add Sale")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("pos")
                                  ? _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link sub-nav-link",
                                          attrs: { href: "/admin/pos" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "bi bi-circle",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "10px",
                                                    height: "10px",
                                                    fill: "currentColor",
                                                    viewBox: "0 0 16 16",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "fill-rule": "evenodd",
                                                      d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "nav-text" },
                                            [_vm._v("POS")]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("return")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("returns") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#Returns",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("returns")
                                },
                              },
                            },
                            [
                              _vm._m(5),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Returns"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("returns"),
                              },
                              attrs: {
                                id: "Returns",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes(
                                  "sale-return-manage"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/add-sale-return",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Add Sale Returns")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes(
                                  "purchase-return-manage"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/add-purchase-return",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Add Return Purchase")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("account")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("accounts") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#account",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("accounts")
                                },
                              },
                            },
                            [
                              _vm._m(6),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Accounts"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("accounts"),
                              },
                              attrs: {
                                id: "account",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes("account-list")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/account_list",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("List Accounts")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes(
                                  "ledger-report"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/ledger" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Ledger Report")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes(
                                  "assets-adjustment"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/adjustment" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Asset Adjustment")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("payment")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/payment-adjustment",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Payments")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("reciepts")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/reciept-adjustment",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Reciepts")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("expense")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/expense-adjustment",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Expense")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("blog-list")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("blog") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                                "data-target": "#blog",
                                role: "button",
                                "aria-expanded": "false",
                                "aria-controls": "blog",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("blog")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "svg-icon nav-icon" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "feather feather-image",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "20px",
                                      height: "20px",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      stroke: "currentColor",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "3",
                                        y: "3",
                                        width: "18",
                                        height: "18",
                                        rx: "2",
                                        ry: "2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: { cx: "8.5", cy: "8.5", r: "1.5" },
                                    }),
                                    _vm._v(" "),
                                    _c("polyline", {
                                      attrs: { points: "21 15 16 10 5 21" },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("News / Blogs"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("blog"),
                              },
                              attrs: {
                                id: "blog",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes("blog-list")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/blog" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Manage Blogs")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("blog-manage")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/blog-category",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Blog Categories")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("content-page-manage")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("contentpages") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                                "data-target": "#contentpages",
                                role: "button",
                                "aria-expanded": "false",
                                "aria-controls": "contentpages",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("contentpages")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "svg-icon nav-icon" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "feather feather-image",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "20px",
                                      height: "20px",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      stroke: "currentColor",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "3",
                                        y: "3",
                                        width: "18",
                                        height: "18",
                                        rx: "2",
                                        ry: "2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: { cx: "8.5", cy: "8.5", r: "1.5" },
                                    }),
                                    _vm._v(" "),
                                    _c("polyline", {
                                      attrs: { points: "21 15 16 10 5 21" },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Content Pages"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("contentpages"),
                              },
                              attrs: {
                                id: "contentpages",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes(
                                  "content-page-manage"
                                )
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: {
                                              to: "/admin/content-page",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Manage Content Pages")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("people")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("people") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#People",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("people")
                                },
                              },
                            },
                            [
                              _vm._m(7),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("People"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("people"),
                              },
                              attrs: {
                                id: "People",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion2" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes("role-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/roles" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Role")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "customer-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/customer" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Customers")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "supplier-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/suppliers" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Suppliers")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes("user-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/users" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Users")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          class: { active: _vm.isActive("reports") },
                          attrs: {
                            "data-toggle": "collapse",
                            href: "#reports",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setActive("reports")
                            },
                          },
                        },
                        [
                          _vm._m(8),
                          _vm._v(" "),
                          _c("span", { staticClass: "nav-text" }, [
                            _vm._v("Reports"),
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-chevron-right fa-rotate-90",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "nav-collapse",
                          class: {
                            "collapse nav-collapse": !_vm.isActive("reports"),
                          },
                          attrs: { id: "reports", "data-parent": "#accordion" },
                        },
                        [
                          _c("ul", { staticClass: "nav flex-column" }, [
                            _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link sub-nav-link",
                                    attrs: { to: "/admin/profit-loss-report" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-circle",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "10px",
                                              height: "10px",
                                              fill: "currentColor",
                                              viewBox: "0 0 16 16",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "nav-text" }, [
                                      _vm._v("Profit / Loss"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link sub-nav-link",
                                    attrs: { to: "/admin/purchase-report" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-circle",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "10px",
                                              height: "10px",
                                              fill: "currentColor",
                                              viewBox: "0 0 16 16",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "nav-text" }, [
                                      _vm._v("Purchase Report"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link sub-nav-link",
                                    attrs: { to: "/admin/supplier-report" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-circle",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "10px",
                                              height: "10px",
                                              fill: "currentColor",
                                              viewBox: "0 0 16 16",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "nav-text" }, [
                                      _vm._v("Supplier Report"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link sub-nav-link",
                                    attrs: {
                                      to: "/admin/stock-history-report",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-circle",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "10px",
                                              height: "10px",
                                              fill: "currentColor",
                                              viewBox: "0 0 16 16",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "nav-text" }, [
                                      _vm._v("Stock History Report"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link sub-nav-link",
                                    attrs: { to: "/admin/order-report" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-circle",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "10px",
                                              height: "10px",
                                              fill: "currentColor",
                                              viewBox: "0 0 16 16",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "nav-text" }, [
                                      _vm._v("Customer Report"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link sub-nav-link",
                                    attrs: { to: "/admin/stock-report" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-circle",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "10px",
                                              height: "10px",
                                              fill: "currentColor",
                                              viewBox: "0 0 16 16",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "nav-text" }, [
                                      _vm._v("Stock Report"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link sub-nav-link",
                                    attrs: { to: "/admin/out-of-stock-report" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-circle",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "10px",
                                              height: "10px",
                                              fill: "currentColor",
                                              viewBox: "0 0 16 16",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "nav-text" }, [
                                      _vm._v("Out Of Stock Report"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link sub-nav-link",
                                    attrs: {
                                      to: "/admin/stock-transfer-report",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-circle",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "10px",
                                              height: "10px",
                                              fill: "currentColor",
                                              viewBox: "0 0 16 16",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "nav-text" }, [
                                      _vm._v("Stock Transfer Report"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "nav-item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link sub-nav-link",
                                    attrs: { to: "/admin/expense-report" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          svg-icon\n                          nav-icon\n                          d-flex\n                          justify-content-center\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-circle",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "10px",
                                              height: "10px",
                                              fill: "currentColor",
                                              viewBox: "0 0 16 16",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "nav-text" }, [
                                      _vm._v("Expense Report"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("NewsletterContact-list")
                      ? _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: { to: "/admin/newslettercontact" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "svg-icon nav-icon" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-envelope font-size-h4",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "nav-text" }, [
                                  _vm._v(" Newsletter Contact "),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("business-setting")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("setting") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#setting",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("setting")
                                },
                              },
                            },
                            [
                              _vm._m(9),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Business Settings"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("setting"),
                              },
                              attrs: {
                                id: "setting",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion3" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes(
                                    "general-setting"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/bussiness-setting",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingB",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("General Settings")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "warehouse-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/warehouse" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Warehouse")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "language-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/languages" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Language")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "currency-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/currencies",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Currency")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "payment-methods-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/payment-methods",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Payment Methods")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "shipping-methods-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/shipping-methods",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Shipping Methods")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "shippment-with-city-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/shippment-with-city-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Shipment With City")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "tax-setting-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/tax-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Tax Settings")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "tax-setting-rate-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/tax-rate-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Tax Rate Settings")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "coupon-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/coupon-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Coupon Settings")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "country-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/country-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Country Settings")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes("state-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/state-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("State Settings")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes("city-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                to: "/admin/city-setting",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("City Settings")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.setting.is_web_purchased === "1" &&
                    _vm.$parent.permissions.includes("website-setting")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("websetting") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#websetting",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("websetting")
                                },
                              },
                            },
                            [
                              _vm._m(10),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Website Settings"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("websetting"),
                              },
                              attrs: {
                                id: "websetting",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion3" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes(
                                    "website-setting"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/website-setting",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("General Settings")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "home-page-builder"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/home-setting",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Home Page Builder")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "menu-builder"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/menu-builder",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Menu Builder")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "slider-bannder-manage"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/slider-setting",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Slider Banners")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "constant-bannder-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/constant-banner",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Constant Banners")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "parrallex-bannder-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/home-banner",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Parallax Banners")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.setting.is_app_purchased === "1" &&
                    _vm.$parent.permissions.includes("mobile-setting")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("appsetting") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#appsetting",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("appsetting")
                                },
                              },
                            },
                            [
                              _vm._m(11),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Mobile App Settings"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("appsetting"),
                              },
                              attrs: {
                                id: "appsetting",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion3" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes(
                                    "mobile-general-setting"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/app-setting",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("General Settings")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "mobile-slider-bannder-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/banner-setting",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Slider Banners")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.setting.is_deliveryboyapp_purchased === "1" &&
                    _vm.$parent.permissions.includes("delivery-boy-list")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("deliveryboy") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#deliveryboy",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("deliveryboy")
                                },
                              },
                            },
                            [
                              _vm._m(12),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Delivery App Settings"),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-right fa-rotate-90",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse":
                                  !_vm.isActive("deliveryboy"),
                              },
                              attrs: {
                                id: "deliveryboy",
                                "data-parent": "#accordion",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "accordion3" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes(
                                    "delivery-boy-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/deliveryboy-list",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Delivery Boys List")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "manage-delivery-boy"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: {
                                                "data-toggle": "collapse",
                                                to: "/admin/deliveryboy",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls": "settingW",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          ",
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Add Delivery Boy")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-boxes font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-boxes font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-quote-right font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-money-check-alt font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-clipboard-check font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-undo-alt font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-file-invoice-dollar font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-user-friends font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-chart-line font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-cogs font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-cogs font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-cogs font-size-h4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-cogs font-size-h4" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }