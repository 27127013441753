var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column-fluid" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-primary white p-2 ml-2",
              staticStyle: { float: "right" },
              attrs: { href: "/sample/sample.zip" },
            },
            [_vm._v("Sample")]
          ),
          _vm._v(" "),
          _c("div"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "gallary0 linked card card-custom gutter-b bg-white border-0",
              attrs: { id: "generalgallary" },
            },
            [
              _c(
                "div",
                { staticClass: "card-header border-0 align-items-center" },
                [
                  _c("div", { staticClass: "icons d-flex" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary white p-2 ml-2",
                        attrs: {
                          type: "button",
                          title: "Add New",
                          "data-toggle": "modal",
                          "data-target": "#imagepopup",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleModal()
                          },
                        },
                      },
                      [_vm._v("\n                Import\n              ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "modal fade text-left",
                        class: { show: _vm.showModal },
                        style: [
                          _vm.showModal
                            ? { display: "block !important" }
                            : { display: "none" },
                        ],
                        attrs: {
                          id: "imagepopup",
                          tabindex: "-1",
                          role: "dialog",
                          "aria-labelledby": "myModalLabel1",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "modal-dialog",
                            attrs: { role: "document" },
                          },
                          [
                            _c("div", { staticClass: "modal-content" }, [
                              _c("div", { staticClass: "modal-header" }, [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "modal-title",
                                    attrs: { id: "myModalLabel1" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        Add File Here\n                      "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "\n                          close\n                          rounded-pill\n                          btn btn-sm btn-icon btn-light btn-hover-primary\n                          m-0\n                        ",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                      "aria-label": "Close",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleModal()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "bi bi-x",
                                        attrs: {
                                          width: "20px",
                                          height: "20px",
                                          viewBox: "0 0 16 16",
                                          fill: "currentColor",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "modal-body" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                        Click in the box for upload file (we accept zip).\n                      "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "avatar-upload mb-3" },
                                  [
                                    _c("div", { staticClass: "avatar-edit" }, [
                                      _c("input", {
                                        attrs: {
                                          type: "file",
                                          id: "zipUpload",
                                          accept: ".zip",
                                        },
                                        on: { change: _vm.onFileChange },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "zipUpload" } },
                                        [_vm._v(" zip upload ")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "avatar-preview" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "rounded",
                                            attrs: { id: "imagePreview" },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "194px",
                                                width: "100%",
                                              },
                                              attrs: { src: _vm.url },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors.has("file")
                                      ? _c("small", {
                                          staticClass: "form-text text-danger",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.errors.get("file")
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-light",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleModal()
                                      },
                                    },
                                  },
                                  [_c("span", {}, [_vm._v("Close")])]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary ml-1",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.importZip()
                                      },
                                    },
                                  },
                                  [_c("span", {}, [_vm._v("Submit")])]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "gallary0 linked card card-custom gutter-b bg-white border-0",
              attrs: { id: "generalgallary" },
            },
            [
              _c(
                "div",
                { staticClass: "card-header border-0 align-items-center" },
                [
                  _c("div", { staticClass: "icons d-flex" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary white p-2 ml-2",
                        attrs: { type: "button", title: "Add New" },
                        on: {
                          click: function ($event) {
                            return _vm.exportProduct()
                          },
                        },
                      },
                      [_vm._v("\n                Export\n              ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.linkShow
                    ? _c(
                        "a",
                        {
                          staticClass: "download",
                          attrs: { href: "/export/products.zip" },
                        },
                        [_vm._v("Download Link")]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.stats
            ? _c("div", {}, [
                _c("p", { staticClass: "stats" }, [
                  _vm._v(
                    " " + _vm._s(this.simple_products) + " New Simple Products"
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "stats" }, [
                  _vm._v(
                    " " +
                      _vm._s(this.variable_products) +
                      " New Variable Products"
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "stats" }, [
                  _vm._v(" " + _vm._s(this.new_attributes) + " New Attributes"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "stats" }, [
                  _vm._v(" " + _vm._s(this.new_variations) + " New Variations"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "stats" }, [
                  _vm._v(" " + _vm._s(this.new_categories) + " New Categories"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "stats" }, [
                  _vm._v(" " + _vm._s(this.new_brands) + " New Brands"),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom bg-transparent shadow-none border-0 mb-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [_vm._v("\n                Import Export\n              ")]
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("br"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }